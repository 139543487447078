import React from "react";
import classes from "./FooterMobile.module.css";
const FooterMobile = (props) => {
  return (
    <div className={classes.FooterMobile}>
      <div className={classes.FooterMobileItem}>
        <div className={classes.FooterMobileTitle}>The ETERNO</div>
        <div style={{ color: "var(--mainColor)" }}>
          Ramkhamhaeng - Kheha Romklao 64
        </div>
        <div>0922-675-675</div>
      </div>
      <div className={classes.FooterMobileItem}>
        <div className={classes.FooterMobileTitle}>Follow Us</div>
        <div>
          {/* <a target="blank" href="https://www.facebook.com/107173497296750"> */}
          <a target="blank" href="fb://page?id=107173497296750">
            <img
              alt=""
              src={require("../../../../assets/images/followUsIcon/facebook_logo.png")}
              style={{ width: 45, margin: 5, cursor: "pointer" }}
            />
          </a>
          <a target="blank" href="https://www.instagram.com/eterno64/">
            <img
              alt=""
              src={require("../../../../assets/images/followUsIcon/instagram_logo.png")}
              style={{ width: 45, margin: 5, cursor: "pointer" }}
            />
          </a>
          <img
            alt=""
            src={require("../../../../assets/images/followUsIcon/line_logo.png")}
            style={{ width: 45, margin: 5, cursor: "pointer" }}
          />
        </div>
      </div>
      {/* <div className={classes.FooterMobileItem}>
        <div style={{ marginTop: 10 }}>
          <img alt=""
            src={require("../../../../assets/images/MailLogo.png")}
            style={{ width: 20 }}
          />
          <span style={{ marginLeft: 10 }}>eterno@mail.com</span>
        </div>
        <div>
          <img alt=""
            src={require("../../../../assets/images/phone.png")}
            style={{ width: 20 }}
          />
          <span style={{ marginLeft: 10, letterSpacing: 2.5 }}>
            02 - 1234567
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default FooterMobile;
