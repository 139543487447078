import React from "react";

const SocialContact = (props) => {
  return (
    <div style={{ margin: "30px ", display: "flex", justifyContent: "center" }}>
      <div style={{ margin: "10px 8%" }}>
        <div style={{ textAlign: "center" }}>
          <a target="blank" href="https://www.facebook.com/107173497296750">
            <img
              style={{ width: 50 }}
              src={require("../../../assets/images/followUsIcon/facebook_logo.png")}
              alt=""
            />
          </a>
        </div>
        <div style={{ textAlign: "center" }}>Facebook</div>
      </div>
      <div style={{ margin: "10px 8%" }}>
        <div style={{ textAlign: "center" }}>
          <a target="blank" href="https://www.instagram.com/eterno64/">
            <img
              style={{ width: 50 }}
              src={require("../../../assets/images/followUsIcon/instagram_logo.png")}
              alt=""
            />
          </a>
        </div>
        <div style={{ textAlign: "center" }}>Instagram</div>
      </div>
      <div style={{ margin: "10px 8%" }}>
        <div style={{ textAlign: "center" }}>
          <img
            style={{ width: 50 }}
            src={require("../../../assets/images/followUsIcon/line_logo.png")}
            alt=""
          />
        </div>
        <div style={{ textAlign: "center" }}>Line</div>
      </div>
    </div>
  );
};

export default SocialContact;
