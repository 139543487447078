import React, { Component } from "react";
import { Container } from "reactstrap";

import FloorBanner from "./FloorBanner/FloorBanner";
import FloorTextBanner from "./FloorTextBanner/FloorTextBanner";
import FloorBlueprint from "./FloorBlueprint/FloorBlueprint";
import FloorImage from "./FloorImage/FloorImage";
import SectionHOC from "../LandingPage/SectionHOC/SectionHOC";

class FloorDetail extends Component {
  state = {};
  render() {
    return (
      <div>
        <FloorBanner imgSrc={this.props.coverSrc} />
        <FloorTextBanner
          title={this.props.title}
          subtitle={this.props.subtitle}
          description={this.props.description}
        />
        <Container style={{ paddingTop: 20 }}>
          <SectionHOC title="แบบแปลนห้อง">
            <FloorBlueprint detailList={this.props.detailList} imgSrc={this.props.blueprintSrc} />
          </SectionHOC>
          <SectionHOC title="รูปตัวอย่างห้อง">
            <FloorImage imgList={this.props.imgList} />
          </SectionHOC>
        </Container>
      </div>
    );
  }
}

export default FloorDetail;
