import React, { Component } from "react";
import FloorDetail from "../../../components/FloorDetail/FloorDetail";

class FloorTwoDetail extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const detailList = [
      { title: "พื้นที่ใช้สอย ( เริ่มต้น )", value: "169", unit: "ตร.ม." },
      { title: "ห้องนอน", value: "4", unit: "ห้อง" },
      { title: "ห้องน้ำ", value: "3", unit: "ห้อง" },
    ];
    const imgList = [
      {
        src: require("../../../assets/images/floor_detail/floor2/bedroom1.jpg"),
        description: "ห้องนอน 1",
      },
      {
        src: require("../../../assets/images/floor_detail/floor2/bedroom2.jpg"),
        description: "ห้องนอน 2",
      },
      {
        src: require("../../../assets/images/floor_detail/floor2/masterBedroom1.jpg"),
        description: "ห้องนอนใหญ่ 1",
      },
      {
        src: require("../../../assets/images/floor_detail/floor2/masterBedroom2.jpg"),
        description: "ห้องนอนใหญ่ 2",
      },
      {
        src: require("../../../assets/images/floor_detail/floor2/office1.jpg"),
        description: "ห้องทำงาน 1",
      },
      {
        src: require("../../../assets/images/floor_detail/floor2/office2.jpg"),
        description: "ห้องทำงาน 2",
      },
    ];

    return (
      <div>
        <FloorDetail
          coverSrc={require("../../../assets/images/floor_detail/prod_Floor2Cover.jpg")}
          title="Floor 2"
          subtitle="ชั่นที่ 2"
          // description="ห้องนั่ง เล่นห้อง นั่งเล่น ห้องนั่งเล่น ห้องนั่งเล่น ห้องนั่งเล่น ห้องนั่งเล่นห้องนั่งเล่นห้องนั่งเล่นห้องนั่งเล่น"
          detailList={detailList}
          blueprintSrc={require("../../../assets/images/blueprint/floor2.jpg")}
          imgList={imgList}
        />
      </div>
    );
  }
}

export default FloorTwoDetail;
