import React, { Component } from "react";
import ContactInfo from "../../components/ContactInfo/ContactInfo";

class ContactPage extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <ContactInfo />
      </div>
    );
  }
}

export default ContactPage;
