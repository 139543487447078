import React from "react";
import classes from "./PhyContact.module.css";

const PhyContact = (prop) => {
  return (
    <div className={classes.PhyContactContainer}>
      <div>
        <div className={classes.Icon}>
          <img src={require("../../../assets/images/contact_logo/phoneLogo.png")} alt="" />
          <div className={classes.hiddenXS}>Phone</div>
        </div>
        <div className={classes.Info}>
          {/* <div>02-117-2500</div> */}
          <div>0922-675-675</div>
          {/* <div>Phone : +6612345678</div> */}
        </div>
      </div>
      <div /*className={classes.MiddleInfo}*/>
        <div className={classes.Icon}>
          <img src={require("../../../assets/images/contact_logo/pinLogo.png")} alt="" />
          <div className={classes.hiddenXS}>Address</div>
        </div>
        <div className={classes.Info}>
          เคหะร่มเกล้า 64 ถนนราษฎร์พัฒนา แขวงคลองสองต้นนุ่น เขตลาดกระบัง กรุงเทพฯ 10500
        </div>
      </div>
      {/* <div>
        <div className={classes.Icon}>
          <img src={require("../../../assets/images/contact_logo/emailLogo.png")} alt="" />
          <div className={classes.hiddenXS}>Email</div>
        </div>
        <div className={classes.Info}>theeternoromklao@gmail.com</div>
      </div> */}
    </div>
  );
};

export default PhyContact;
