import React, { Component } from "react";
import Promotion from "../../components/Promotion/Promotion";

class PromotionPage extends Component {
  state = {};
  render() {
    return <Promotion />;
  }
}

export default PromotionPage;
