import React, { useState, useEffect } from "react";
// import logo from "./logo.svg";
import "./App.css";
import classes from "./App.module.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import LandingPage from "./containers/LandingPage/LandingPage";
import HouseOverallPage from "./containers/HouseOverallPage/HouseOverallPage";
import ContactPage from "./containers/ContactPage/ContactPage";
import FloorOneDetail from "./containers/FloorDetail/FloorOneDetail/FloorOneDetail";
import FloorTwoDetail from "./containers/FloorDetail/FloorTwoDetail/FloorTwoDetail";
import FloorThreeDetail from "./containers/FloorDetail/FloorThreeDetail/FloorThreeDetail";
import ProjectDetailPage from "./containers/ProjectDetailPage/ProjectDetailPage";
import PromotionPage from "./containers/PromotionPage/PromotionPage";
import NavBar from "./components/General/NavBar/NavBar";
import Footer from "./components/General/Footer/Footer";
import PageNotFound from "./containers/PageNotFound/PageNotFound";

function App() {
  // handler NavBar Selected
  const [navSelected, setNavSelected] = useState(null);
  useEffect(() => {
    const fullpath = window.location.pathname;
    const parentPath = fullpath.split("/")[1];
    switch (parentPath) {
      case "house_overall":
        setNavSelected("houseOverallPage");
        break;
      case "project_detail":
        setNavSelected("projectDetailPage");
        break;
      case "promotion":
        setNavSelected("promotionPage");
        break;
      case "contact":
        setNavSelected("contactPage");
        break;
      default:
        setNavSelected("");
        break;
    }
  }, []);

  const changePageNameToNum = (pageName) => {
    let pageNum = 0;
    switch (pageName) {
      case "promotionPage":
        pageNum = 1;
        break;
      case "projectDetailPage":
        pageNum = 2;
        break;
      case "houseOverallPage":
        pageNum = 3;
        break;
      case "contactPage":
        pageNum = 4;
        break;
      default:
        pageNum = 0;
        break;
    }
    return pageNum;
  };

  const pageNum = changePageNameToNum(navSelected);

  return (
    <div style={{ minHeight: "100vh", position: "relative" }}>
      <BrowserRouter>
        <NavBar navSelectedHandler={setNavSelected} navSelectedNum={pageNum} />
        <div className={classes.ContentWrapper}>
          <Switch>
            <Route
              exact
              path="/"
              component={() => (
                <LandingPage changePageHandler={setNavSelected} />
              )}
            />
            <Route exact path="/house_overall" component={HouseOverallPage} />
            <Route
              exact
              path="/house_overall/floor1"
              component={FloorOneDetail}
            />
            <Route
              exact
              path="/house_overall/floor2"
              component={FloorTwoDetail}
            />
            <Route
              exact
              path="/house_overall/floor3"
              component={FloorThreeDetail}
            />
            <Route exact path="/contact" component={ContactPage} />
            <Route exact path="/project_detail" component={ProjectDetailPage} />
            <Route exact path="/promotion" component={PromotionPage} />
            <Route exact path="" component={PageNotFound} />
          </Switch>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
