import React, { Component } from "react";
import classes from "./FloorBlueprint.module.css";

class FloorBlueprint extends Component {
  state = {};

  render() {
    // const dataTableRow = this.props.detailList.map((elem) => {
    //   return (
    //     <tr key={elem.title} style={{ borderBottom: "1px solid #E5E5E5" }}>
    //       <td className={classes.TitleText} style={{ width: "250px" }}>
    //         {elem.title}
    //       </td>
    //       <td className={classes.ValueText} style={{ textAlign: "right" }}>
    //         {elem.value}
    //       </td>
    //       <td
    //         style={{
    //           verticalAlign: "bottom",
    //           lineHeight: "40px",
    //           paddingLeft: 15,
    //         }}
    //       >
    //         {elem.unit}
    //       </td>
    //     </tr>
    //   );
    // });

    // const tableData = (
    //   <table style={{ margin: "auto", width: "80%" }}>
    //     <thead></thead>
    //     <tbody>{dataTableRow}</tbody>
    //   </table>
    // );

    const imageData = (
      <img
        className={classes.BlueprintImgSize}
        src={this.props.imgSrc}
        // style={{ width: "70%" }}
        alt=""
      />
    );
    return (
      // <div className={classes.FloorblueprintContainer}>
      // {/* <div className={classes.DataList}>{tableData}</div> */}
      <div className={classes.BlueprintImg}>{imageData}</div>
      // </div>
    );
  }
}

export default FloorBlueprint;
