import React, { Component } from "react";
import PhyContact from "./PhyContact/PhyContact";
import SocialContact from "./SocialContact/SocialContact";
import MapContact from "./MapContact/MapContact";

class ContactInfo extends Component {
  state = {};
  render() {
    return (
      <div>
        <div style={{ textAlign: "center", margin: "20px 0" }}>
          <h1 style={{ color: "var(--mainColor)" }}>Contact</h1>
        </div>
        <PhyContact />
        <SocialContact />
        <MapContact />
      </div>
    );
  }
}

export default ContactInfo;
