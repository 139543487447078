import React, { Component } from "react";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

class PageNotFound extends Component {
  state = {};
  render() {
    return (
      <div>
        <div style={{ textAlign: "center", margin: "20px 0" }}>
          <h1 style={{ color: "var(--mainColor)" }}>404 Page Not Found</h1>
        </div>
        <Container style={{ padding: "0 10px" }}>
          <div
            style={{
              textAlign: "center",
              color: "darkgrey",
              fontSize: 18,
              height: "100%",
            }}
          >
            <img
              src={require("../../assets/images/promotion/apology.jpg")}
              style={{ width: "30vh", marginBottom: 20 }}
              alt="apology"
            />
            <div>ไม่พบหน้าที่ท่านต้องการ</div>
            <Link to="/">กลับไปหน้าแรก</Link>
          </div>
        </Container>
      </div>
    );
  }
}

export default PageNotFound;
