import React, { Component } from "react";
import classes from "./ProjectDetail.module.css";
import SectionHOC from "../SectionHOC/SectionHOC";
import ProjectInfo from "./ProjectInfo/ProjectInfo";
import Factsheet from "./Factsheet/Facsheet";
class ProjectDetail extends Component {
  state = { displayType: 0 };

  changeContentHandler = (contentType) => {
    this.setState({ displayType: contentType });
  };

  render() {
    // const subSecHeaderClass = ;
    const contentType = this.state.displayType;
    let displayContent = null;
    switch (contentType) {
      case 0:
        displayContent = <ProjectInfo changePageHandler={this.props.changePageHandler} />;
        break;
      case 1:
        displayContent = <Factsheet />;
        break;
      default:
        displayContent = <ProjectInfo changePageHandler={this.props.changePageHandler} />;
        break;
    }
    return (
      <SectionHOC title="ข้อมูลโครงการ">
        <div style={{ textAlign: "center", paddingBottom: 10 }}>
          <span
            className={[classes.SubSecHeader, contentType === 0 ? classes.Active : ""].join(" ")}
            onClick={this.changeContentHandler.bind(this, 0)}
          >
            ข้อมูลโครงการ
          </span>
          <span
            className={[classes.SubSecHeader, contentType === 1 ? classes.Active : ""].join(" ")}
            onClick={this.changeContentHandler.bind(this, 1)}
          >
            FACTSHEET
          </span>
        </div>
        {displayContent}
      </SectionHOC>
    );
  }
}

export default ProjectDetail;
