import React from "react";
import classes from "./LocationWidget.module.css";

const LocationWidget = (props) => {
  return (
    <div style={{ textAlign: "center", margin: "0 10px" }}>
      <div className={classes.WidgetItem}>
        <div>
          <div className={classes.WidgetValue}>{props.value}</div>
          <div className={classes.WidgetUnit}>{props.unit}</div>
        </div>
      </div>
      <span className={classes.DescText}>{props.desc1line}</span><br />
      <span className={classes.DescText}>{props.desc2line}</span>
    </div>
  );
};

export default LocationWidget;
