import React, { Component } from "react";
import classes from "./FloorInformation.module.css";
import { Link } from "react-router-dom";

class FloorInformation extends Component {
  state = {};

  render() {
    const textSectionClass = [
      classes.TextSection,
      this.props.mobileSize ? classes.MobileTextSectionSize : "",
    ].join(" ");

    const textSection = (
      <div className={textSectionClass}>
        <div>
          <div className={classes.FloorTitle}>{this.props.title}</div>
          <div className={classes.FloorSubtitle}>{this.props.subtitle}</div>
          {/* <div className={classes.FloorDescription}>{this.props.description}</div> */}
          <div style={{ margin: "15px 0 20px 0" }}>
            <Link className={classes.FloorButton} to={this.props.linkHref}>
              ดูรายละเอียด
            </Link>
          </div>
        </div>
      </div>
    );

    const coverSectionClass = [
      classes.CoverSection,
      this.props.mobileSize ? classes.MobilePicSize : "",
    ].join(" ");

    const imageCover = (
      <div className={coverSectionClass}>
        <img
          src={this.props.imageSrc}
          style={{
            width: "100%",
            objectFit: "cover",
            height: this.props.mobileSize ? "70vw" : 600,
          }}
          alt=""
        />
      </div>
    );

    const containerClass = [
      classes.FloorInformationContainer,
      this.props.mobileSize ? classes.FlexCol : "",
    ].join(" ");

    const finalComb =
      !this.props.left && !this.props.mobileSize ? (
        <div className={containerClass}>
          {textSection}
          {imageCover}
        </div>
      ) : (
        <div className={containerClass}>
          {imageCover}
          {textSection}
        </div>
      );

    return finalComb;
  }
}

export default FloorInformation;
