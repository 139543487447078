import React, { Component } from "react";
// import classes from "./FloorTextBanner.module.css";

class FloorTextBanner extends Component {
  state = {};
  render() {
    return (
      <div style={{ textAlign: "center", padding: "30px 0", backgroundColor: "#F3F3F3" }}>
        <h1>{this.props.title}</h1>
        <h5 style={{ color: "var(--mainColor)" }}>{this.props.subtitle}</h5>
        <h3>{this.props.description}</h3>
      </div>
    );
  }
}

export default FloorTextBanner;
