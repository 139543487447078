import React, { Component } from "react";
import { Container } from "reactstrap";
import classes from "./ProjectDetailFull.module.css";

class ProjectDetailFull extends Component {
  state = {};
  render() {
    return (
      <div>
        <div>
          <div style={{ textAlign: "center", margin: "20px 0" }}>
            <h1 style={{ color: "var(--mainColor)" }}>The ETERNO 64</h1>
            <h5>รามคำแหง - เคหะร่มเกล้า 64</h5>
          </div>
        </div>
        <div style={{ padding: "0 5%", marginBottom: 20 }}>
          <img
            src={require("../../assets/images/banner/eterno64-banner1.jpg")}
            alt=""
            style={{ width: "100%" }}
          />
        </div>
        <Container>
          <div style={{ textAlign: "center", marginBottom: 20 }}>
            <div style={{ fontSize: 30, fontWeight: "bold", color: "#303030" }}>
              The Eterno Townhome
            </div>
            <div
              style={{
                fontSize: 26,
                fontWeight: "bold",
                color: "var(--mainColor)",
                marginBottom: 10,
              }}
            >
              Ramkhamhaeng - Kheha Romklao 64
            </div>

            <div style={{ fontSize: 25 }}>
              เพราะชีวิตคือการเดินทาง...เปิดมุมมองที่แตกต่างให้กับชีวิต
            </div>
          </div>
          <div style={{ textAlign: "center", margin: "20px 0" }}>
            <img
              src={require("../../assets/images/projectDetail/OTIL4174.JPG")}
              style={{ width: "60%" }}
              alt=""
            />
          </div>

          <div
            style={{
              textIndent: 70,
              textAlign: "justify",
              fontSize: 18,
              marginBottom: 20,
            }}
          >
            <span style={{ color: "var(--mainColor)", fontWeight: "bold" }}>
              พื้นที่แห่งความสุข
            </span>{" "}
            ทาวน์โฮม ที่ให้ความเป็นส่วนตัว รื่นรมย์กับพื้นที่สีเขียว
            ให้ความรู้สึกเหมือนอยู่บ้านเดี่ยว สัมผัสอากาศบริสุทธิ์ของต้นไม้
          </div>
          <div style={{ textAlign: "center", margin: "20px 0" }}>
            <img
              src={require("../../assets/images/projectDetail/SBBP1740.JPG")}
              style={{ width: "60%" }}
              alt=""
            />
          </div>
          <div
            style={{
              textIndent: 70,
              textAlign: "justify",
              fontSize: 18,
              marginBottom: 20,
            }}
          >
            <span style={{ color: "var(--mainColor)", fontWeight: "bold" }}>
              โอบล้อมด้วยสังคมเมืองแบบครบครัน
            </span>{" "}
            ใกล้สิ่งอำนวยความสะดวกมากมาย Community Mall เพียง 200 เมตรจากโครงการ ร้านค้า
            ร้านอาหารและบริการที่ตอบสนอง Lifestyle แบบคนรุ่นใหม่ เดินทางสะดวก
            อยู่ใกล้เส้นทางรถไฟฟ้าสายสีส้ม ที่จะทำการก่อสร้างในอนาคต
          </div>

          <div style={{ padding: "0 10%", marginBottom: 20 }}>
            <div className={classes["video-container"]}>
              <iframe
                title="The Eterno64"
                className={classes["video"]}
                src="https://www.youtube.com/embed/hRfYweKiPwM"
                allowFullScreen="allowfullscreen"
              ></iframe>
            </div>
          </div>
          <div style={{ textAlign: "center", fontSize: 12, color: "grey", margin: "20px 0" }}>
            โครงการ ดิ อิเธอโน่ รามคำแหง-ร่มเกล้า เจ้าของกรรมสิทธิ์ในที่ดิน บริษัท ตั้งไชยวงศ์
            ดีเวลลอปเม้น จำกัด • ทุนจดทะเบียน 100 ล้านบาท (ชำระเต็ม) กรรมการบริษัท นายสุวัฒน์
            ตั้งไชยวงศ์ • ที่ตั้งสำนักงานใหญ่ เลขที่ 1 อาคารธาราการ์เด้น ซอยรามคำแหง 164 แยก 2
            ถนนรามคำแหง แขวงมีนบุรี เขตมีนบุรี กรุงเทพมหานคร 10510 โทรศัพท์ 02-117-2500 •
            ที่ตั้งโครงการ ซอยเคหะร่มเกล้า 64 ถนนราษฎร์พัฒนา แขวงคลองสองตันนุ่น เขตลาดกระบัง
            กรุงเทพมหานคร โฉนดที่ดินเลขที่ 24443, 26196 เนื้อที่โครงการ 5-1-78.9 ไร่ • เริ่มก่อสร้าง
            ธ.ค. 2559 คาดว่าจะแล้วเสร็จประมาณ ต.ค. 2561 มีภาระผูกพันกับบริษัท ไทยสมุทรประกันชีวิต
            จำกัด (มหาชน)
          </div>
        </Container>
      </div>
    );
  }
}

export default ProjectDetailFull;
