import React, { Component } from "react";
import { Container } from "reactstrap";
// import PromotionItem from "./PromotionItem/PromotionItem";

class Promotion extends Component {
  state = {};
  render() {
    /*** Promotion Item Example ***/
    // const promotionData = [
    //   {
    //     title: "Promotion Title 1",
    //     description:
    //       "น้องใหม่ออยล์ช็อปโบกี้ ออสซี่ คันถธุระคอร์ปอเรชั่น วินหมวยไนน์ ไบโอ ร็อคก่อนหน้าคอปเตอร์ เก๋ากี้ ซาบะ",
    //     startTime: "20/01/2563",
    //     endTime: "30/12/2563",
    //     imgSrc: require("../../assets/images/banner/eterno64-banner.jpg"),
    //   },
    // ];
    //
    // const promoItems = promotionData.map((elem) => (
    //   <PromotionItem
    //     key={elem.title}
    //     title={elem.title}
    //     description={elem.description}
    //     startTime={elem.startTime}
    //     endTime={elem.endTime}
    //     imgSrc={elem.imgSrc}
    //   />
    // ));
    /*** Promotion Item Example ***/

    return (
      <div /*style={{ marginBottom: 20 }}*/>
        <div style={{ textAlign: "center", margin: "20px 0" }}>
          <h1 style={{ color: "var(--mainColor)" }}>Promotion</h1>
        </div>
        <Container style={{ padding: "0 10px" }}>
          {/* {promoItems} */}
          <div
            style={{
              textAlign: "center",
              color: "darkgrey",
              fontSize: 18,
              height: "100%",
            }}
          >
            <img
              src={require("../../assets/images/promotion/apology.jpg")}
              style={{ width: "30vh", marginBottom: 20 }}
              alt="apology"
            />
            <div>ขณะนี้ยังไม่มีโปรโมชั่นที่เข้าร่วม</div>
          </div>
        </Container>
      </div>
    );
  }
}

export default Promotion;
