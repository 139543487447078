import React, { Component } from "react";
import classes from "./BlueprintDetail.module.css";
import SectionHOC from "../SectionHOC/SectionHOC";
import BlueprintSlide from "./BlueprintSlide/BlueprintSlide";

class BlueprintDetail extends Component {
  state = { contentType: 0 };

  // changeContentHandler = (contentType) => {
  //   this.setState({ contentType: contentType });
  // };

  render() {
    // const contentType = this.state.contentType;
    return (
      <SectionHOC title="แบบแปลนบ้าน">
        <div style={{ textAlign: "center", paddingBottom: 10 }}>
          {/* <span
            className={[classes.SubSecHeader, contentType === 0 ? classes.Active : ""].join(" ")}
            onClick={this.changeContentHandler.bind(this, 0)}
          >
            แบบแปลนบ้าน
          </span> */}
          {/* <span
            className={[classes.SubSecHeader, contentType === 1 ? classes.Active : ""].join(" ")}
            onClick={this.changeContentHandler.bind(this, 1)}
          >
            แบบแปลนโครงการ
          </span> */}
        </div>
        <div className={classes.BlueprintSlide}>
          {/* {this.state.contentType === 0 ? ( */}
          <BlueprintSlide />
          {/* ) : (
            <img
              src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
              alt=""
              style={{ width: "100%" }}
            />
          )} */}
        </div>
      </SectionHOC>
    );
  }
}

export default BlueprintDetail;
