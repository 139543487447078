import React, { Component } from "react";
import { Link } from "react-router-dom";
import classes from "./NavBarMobile.module.css";
class NavBarMobile extends Component {
  state = {
    openMenu: false,
  };

  clickMenuBtnHandler = () => {
    this.setState({ openMenu: !this.state.openMenu });
  };

  clickNavItemHandler = (pageName) => {
    this.setState({ openMenu: false });
    this.props.changePage(pageName);
  };

  render() {
    const navBarClass = [
      classes.NavBarXs,
      this.state.openMenu ? classes.ActiveMenu : "",
    ].join(" ");
    return (
      <div>
        <div className={classes.ReservedSpace} />
        <div className={navBarClass}>
          <div className={classes.NavBrandXs}>
            <Link
              to="/"
              onClick={this.clickNavItemHandler.bind(this, "landingPage")}
            >
              <img
                src={require("../../../../assets/images/logo/eterno64-logo-half.png")}
                alt="banner"
              />
            </Link>
          </div>
          <div className={classes.NavMenu} onClick={this.clickMenuBtnHandler}>
            <img
              src={require("../../../../assets/images/menu_hamburger.png")}
              alt=""
              style={{ width: 28 }}
            />
          </div>
          <Link
            className={classes.MyLink}
            to="/house_overall"
            onClick={this.clickNavItemHandler.bind(this, "houseOverallPage")}
          >
            <div
              className={
                classes.NavItemXs +
                " " +
                (this.props.currentPage === 3 ? classes.ActiveNav : "")
              }
            >
              รายละเอียดบ้าน
            </div>
          </Link>
          <Link
            className={classes.MyLink}
            to="/project_detail"
            onClick={this.clickNavItemHandler.bind(this, "projectDetailPage")}
          >
            <div
              className={
                classes.NavItemXs +
                " " +
                (this.props.currentPage === 2 ? classes.ActiveNav : "")
              }
            >
              ข้อมูลโครงการ
            </div>
          </Link>

          <Link
            className={classes.MyLink}
            to="/promotion"
            onClick={this.clickNavItemHandler.bind(this, "promotionPage")}
          >
            <div
              className={
                classes.NavItemXs +
                " " +
                (this.props.currentPage === 1 ? classes.ActiveNav : "")
              }
            >
              โปรโมชั่น
            </div>
          </Link>
          <Link
            className={classes.MyLink}
            to="/contact"
            onClick={this.clickNavItemHandler.bind(this, "contactPage")}
          >
            <div
              className={
                classes.NavItemXs +
                " " +
                (this.props.currentPage === 4 ? classes.ActiveNav : "")
              }
            >
              ติดต่อ
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default NavBarMobile;
