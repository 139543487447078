import React from "react";

const SectionHOC = (props) => {
  return (
    <div style={{ marginBottom: 20 }}>
      <div
        style={{
          borderBottom: "1px solid #DBDBDB",
          marginBottom: 20,
          color: "#4A4A4A",
          fontSize: 20,
        }}
      >
        {props.title}
      </div>
      {props.children}
    </div>
  );
};

export default SectionHOC;
