import React, { Component } from "react";
// import { Container } from "reactstrap";
import ProjectDetailFull from "../../components/ProjectDetail/ProjectDetailFull";

class ProjectDetailPage extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        {/* <Container> */}
        <ProjectDetailFull />
        {/* </Container> */}
      </div>
    );
  }
}

export default ProjectDetailPage;
