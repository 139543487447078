import React, { Component } from "react";
import classes from "./LocationDetail.module.css";
// import GoogleMapReact from "google-map-react";

import SectionHOC from "../SectionHOC/SectionHOC";
import LocationWidget from "./LocationWidget/LocationWidget";
import MapContact from "../../ContactInfo/MapContact/MapContact";

class LocationDetail extends Component {
  render() {
    const locationData = [
      { value: "15", unit: "นาที", desc1line: "จากปากซอย", desc2line:"มีสทีน" },
      { value: "40", unit: "นาที", desc1line: "สู่ใจกลาง", desc2line:"กรุงเทพ" },
      { value: "20", unit: "นาที", desc1line: "ถึงสนามบิน", desc2line:"สุวรรณภูมิ" },
    ];

    const locationDataRender = locationData.map((elem) => (
      <LocationWidget key={elem.value} value={elem.value} unit={elem.unit} desc1line={elem.desc1line} desc2line={elem.desc2line} />
    ));

    return (
      <SectionHOC title="ทำเลที่ตั้ง">
        <div className={classes.LocationDetailHeader}>
          <div className={classes.LocationTitle}>The Eterno Townhome</div>
          <div className={classes.LocationSubTitle}>
            เพราะชีวิตคือการเดินทาง...เปิดมุมมองที่แตกต่างให้กับชีวิต
          </div>
        </div>

        <div className={classes.LocationWidgetContainer}>{locationDataRender}</div>
        <MapContact />
      </SectionHOC>
    );
  }
}

export default LocationDetail;
