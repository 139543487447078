import React from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import classes from "./ProjectInfo.module.css";
const ProjectInfo = (props) => {
  return (
    <Row>
      <Col md={6} style={{ marginBottom: 10 }}>
        <img
          // src={require("../../../../assets/images/projectDetail/ProjectInfoImg.png")}
          src={require("../../../../assets/images/projectDetail/eterno-64-project-detail.jpg")}
          style={{ width: "100%" }}
          alt="Home"
        />
      </Col>
      <Col md={6} className={classes.ProjectInfoText}>
        <div>
          <span style={{ fontSize: 22, fontWeight: "bold", color: "#303030" }}>
            โอบล้อมด้วยสังคมเมืองแบบครบครัน
          </span>
        </div>
        <div style={{ marginBottom: 15 }}>
          <span style={{ fontSize: 18, fontWeight: "bold", color: "var(--mainColor)" }}>
            ใกล้สิ่งอำนวยความสะดวกมากมาย
          </span>
        </div>
        <div>
          Community Mall เพียง 200 เมตรจากโครงการ ร้านค้า ร้านอาหารและบริการที่ตอบสนอง Lifestyle
          แบบคนรุ่นใหม่
        </div>
        <div className={classes.ReadMoreBtn}>
          <Link
            to="project_detail"
            onClick={props.changePageHandler.bind(this, "projectDetailPage")}
          >
            {"อ่านเพิ่มเติม >>"}
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default ProjectInfo;
