import React from "react";
import { Col, Row } from "reactstrap";
import classes from "./Factsheet.module.css";

const Factsheet = (props) => {
  const factData = [
    { title: "ราคาเริ่มต้น", value: "3.59", unit: "ล้านบาท" },
    { title: "พื้นที่โครงการ", value: "5", unit: "ไร่" },
    { title: "พื้นที่ใช้สอย ( เริ่มต้น )", value: "169", unit: "ตร.ม." },
    { title: "จำนวนชั้น", value: "3", unit: "ชั้น" },
    { title: "ห้องนอน", value: "4", unit: "ห้อง" },
    { title: "ห้องน้ำ", value: "3", unit: "ห้อง" },
    { title: "ห้องทำงาน", value: "1", unit: "ห้อง" },
  ];

  const dataRender = factData.map((elem) => {
    return (
      <tr key={elem.title} style={{ borderBottom: "1px solid #E5E5E5" }}>
        <td className={classes.TitleText} style={{ width: "250px" }}>
          {elem.title}
        </td>
        <td className={classes.ValueText} style={{ textAlign: "right" }}>
          {elem.value}
        </td>
        <td
          className={classes.UnitText}
          style={{
            verticalAlign: "bottom",
            lineHeight: "40px",
            paddingLeft: 15,
          }}
        >
          {elem.unit}
        </td>
      </tr>
    );
  });

  return (
    <Row>
      <Col md={6} style={{ marginBottom: 10 }}>
        <div>
          <div style={{ marginBottom: 20 }}>
            <span style={{ color: "var(--mainColor)", fontSize: 30 }}>The Eterno </span>
            <span style={{ fontSize: 16, marginLeft: 10 }}>รามคำแหง - เคหะร่มเกล้า 64</span>
          </div>
          <div>
            <table style={{ margin: "0 auto" }}>
              <thead></thead>
              <tbody>{dataRender}</tbody>
            </table>
          </div>
        </div>
      </Col>
      <Col md={6} style={{ textAlign: "center" }}>
        <img
          src={require("../../../../assets/images/factsheet/eterno_factsheet_pic.jpg")}
          style={{ height: "506px", maxWidth: "100%" }}
          alt="Townhome"
        />
      </Col>
    </Row>
  );
};

export default Factsheet;
