import React, { Component } from "react";
import NavBarDesktop from "./NavBarDesktop/NavBarDesktop";
import NavBarMobile from "./NavBarMobile/NavBarMobile";

class NavBar extends Component {
  state = { mobileSize: false };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ mobileSize: window.innerWidth <= 768 });
  };

  render() {
    return this.state.mobileSize ? (
      <NavBarMobile
        changePage={this.props.navSelectedHandler}
        currentPage={this.props.navSelectedNum}
      />
    ) : (
      <NavBarDesktop
        changePage={this.props.navSelectedHandler}
        currentPage={this.props.navSelectedNum}
      />
    );
  }
}

export default NavBar;
