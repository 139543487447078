import React, { Component } from "react";
import FloorInformation from "../../components/HouseOverall/FloorInformation/FloorInformation";

class HouseOverallPage extends Component {
  state = { mobileSize: false };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    // console.log(window.innerWidth < 660);
    this.setState({ mobileSize: window.innerWidth < 768 });
  };

  render() {
    const floorData = [
      {
        title: "Floor 1",
        subtitle: "ชั้นที่ 1",
        imageSrc: require("../../assets/images/floor_detail/prod_Floor1Cover.jpg"),
        left: true,
        linkHref: "/house_overall/floor1",
      },
      {
        title: "Floor 2",
        subtitle: "ชั้นที่ 2",
        imageSrc: require("../../assets/images/floor_detail/prod_Floor2Cover.jpg"),
        left: false,
        linkHref: "/house_overall/floor2",
      },
      {
        title: "Floor 3",
        subtitle: "ชั้นที่ 3",
        imageSrc: require("../../assets/images/floor_detail/prod_Floor3Cover.jpg"),
        left: true,
        linkHref: "/house_overall/floor3",
      },
    ];

    const floorInfo = floorData.map((elem) => (
      <FloorInformation
        key={elem.title}
        title={elem.title}
        subtitle={elem.subtitle}
        description={elem.description}
        imageSrc={elem.imageSrc}
        left={elem.left}
        mobileSize={this.state.mobileSize}
        linkHref={elem.linkHref}
      />
    ));
    return (
      <div>
        <div style={{ marginBottom: 30 }}>{floorInfo}</div>
      </div>
    );
  }
}

export default HouseOverallPage;
