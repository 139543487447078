import React, { Component } from "react";
import classes from "./TextBanner.module.css";
import { Link } from "react-router-dom";

class TextBanner extends Component {
  state = {};
  render() {
    return (
      <div className={classes.TextBanner}>
        <div style={{ marginTop: 30 }}>
          <span style={{ fontSize: 30, fontWeight: "bold" }}>The Eterno Townhome</span>
        </div>
        <div style={{ marginBottom: 30 }}>
          <span style={{ fontSize: 25, color: "var(--mainColor)", fontWeight: "bold" }}>
            เพราะชีวิตคือการเดินทาง ก้าวสู่มุมมองใหม่กับชีวิตที่คุณสรรค์สร้างเอง
          </span>
        </div>
        <div style={{ marginBottom: 30 }}>
          <span style={{ fontSize: 20 }}>
            ทาวน์โฮม...ที่ให้ความเป็นส่วนตัวรื่นรมย์กับพื้นที่สีเขียว
            ให้ความรู้สึกเหมือนอยู่บ้านเดี่ยว สัมผัสอากาศบริสุทธิ์ของต้นไม้
          </span>
        </div>
        <div style={{ marginBottom: 30 }}>
          {/* <button className={classes.EternoBtn}> */}
          <Link
            className={classes.EternoBtn}
            to="/house_overall"
            onClick={this.props.changePageHandler.bind(this, "houseOverallPage")}
          >
            ดูรายละเอียดบ้าน
          </Link>
          {/* </button> */}
        </div>
      </div>
    );
  }
}

export default TextBanner;
