import React, { Component } from "react";
import { Link } from "react-router-dom";
import classes from "./NavBarDesktop.module.css";

class NavBarDesktop extends Component {
  state = { renderSmall: false };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = (event) => {
    const { pageYOffset } = window;
    const renderSmall = this.state.renderSmall;
    const offsetThreshold = 50;

    if (pageYOffset >= offsetThreshold && !renderSmall) {
      this.setState({ renderSmall: true });
    } else if (pageYOffset < offsetThreshold && renderSmall) {
      this.setState({ renderSmall: false });
    }
  };

  render() {
    const bigLogo = require("../../../../assets/images/logo/eterno64-logo-full.png");
    const smallLogo = require("../../../../assets/images/logo/eterno64-logo-small.png");

    const navItemClass = [
      classes.NavItem,
      this.state.renderSmall ? classes.PaddingTopSmall : classes.PaddingTopBig,
    ].join(" ");

    const navBarClass = [
      classes.NavBar,
      this.state.renderSmall ? classes.HeightSmall : classes.HeightBig,
    ].join(" ");

    return (
      <div>
        <div className={classes.ReservedSpace}></div>
        <div className={navBarClass}>
          <Link
            to="/house_overall"
            onClick={this.props.changePage.bind(this, "houseOverallPage")}
            className={
              navItemClass +
              " " +
              (this.props.currentPage === 3 ? classes.ActiveNav : "")
            }
          >
            <div style={{ height: "100%" }}>รายละเอียดบ้าน</div>
          </Link>
          <Link
            to="/project_detail"
            onClick={this.props.changePage.bind(this, "projectDetailPage")}
            className={
              navItemClass +
              " " +
              (this.props.currentPage === 2 ? classes.ActiveNav : "")
            }
          >
            <div style={{ height: "100%" }}>ข้อมูลโครงการ</div>
          </Link>
          <div className={classes.NavBrand}>
            <Link
              to="/"
              onClick={this.props.changePage.bind(this, "landingPage")}
            >
              <img src={this.state.renderSmall ? smallLogo : bigLogo} alt="" />
            </Link>
          </div>

          <Link
            className={
              navItemClass +
              " " +
              (this.props.currentPage === 1 ? classes.ActiveNav : "")
            }
            to="/promotion"
            onClick={this.props.changePage.bind(this, "promotionPage")}
          >
            <div style={{ height: "100%" }}>โปรโมชั่น</div>
          </Link>
          <Link
            to="/contact"
            onClick={this.props.changePage.bind(this, "contactPage")}
            className={
              navItemClass +
              " " +
              (this.props.currentPage === 4 ? classes.ActiveNav : "")
            }
          >
            <div style={{ height: "100%" }}>ติดต่อ</div>
          </Link>
        </div>
      </div>
    );
  }
}

export default NavBarDesktop;
