import React, { Component } from "react";
import classes from "./FloorBanner.module.css";

class FloorBanner extends Component {
  state = {};
  render() {
    return (
      <div>
        <img className={classes.Banner} src={this.props.imgSrc} alt="" />
      </div>
    );
  }
}

export default FloorBanner;
