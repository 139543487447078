import React, { Component } from "react";
import classes from "./FloorImage.module.css";

class FloorImage extends Component {
  state = {};
  render() {
    const imageContainer = this.props.imgList.map((elem) => {
      return (
        <div key={elem.description} style={{ textAlign: "center" }}>
          <img src={elem.src} alt="" style={{ width: "80%", marginTop: 20 }} />
          <div className={classes.ImgDesc}>{elem.description}</div>
        </div>
      );
    });

    return <div style={{ marginBottom: 20 }}>{imageContainer}</div>;
  }
}

export default FloorImage;
