import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import classes from "./BlueprintSlide.module.css";

class BlueprintSlide extends Component {
  state = { currentImgIndex: 0 };
  changeImageInCarouselHandler = (index) => {
    this.setState({ currentImgIndex: index });
  };
  changeImageHandler = (index) => {
    this.setState({ currentImgIndex: index });
  };

  render() {
    const imagesToShow = [
      {
        name: "Floor 1",
        src: require("../../../../assets/images/blueprint/floor1.jpg"),
        // "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E",
      },
      {
        name: "Floor 2",
        src: require("../../../../assets/images/blueprint/floor2.jpg"),
        // "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa20%20text%20%7B%20fill%3A%23444%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa20%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23666%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22247.3203125%22%20y%3D%22218.3%22%3ESecond%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E",
      },
      {
        name: "Floor 3",
        src: require("../../../../assets/images/blueprint/floor3.jpg"),
        // "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E",
      },
    ];
    const imageInCarousel = imagesToShow.map((elem) => (
      <div key={elem.name}>
        <img src={elem.src} alt={elem.name} />
      </div>
    ));

    const imageThumbController = imagesToShow.map((elem, index) => (
      <div
        key={elem.name}
        onClick={this.changeImageInCarouselHandler.bind(this, index)}
        style={{ margin: "0 20px ", textAlign: "center", width: 150, padding: 5 }}
        className={this.state.currentImgIndex === index ? classes.Active : classes.Inactive}
      >
        <img src={elem.src} alt={elem.name} style={{ width: "100%" }} />
        <div>{elem.name}</div>
      </div>
    ));

    return (
      <div>
        <Carousel
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          selectedItem={this.state.currentImgIndex}
          onChange={this.changeImageHandler}
        >
          {imageInCarousel}
        </Carousel>
        <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
          {imageThumbController}
        </div>
      </div>
    );
  }
}

export default BlueprintSlide;
