import React, { Component } from "react";
import FooterDesktop from "./FooterDesktop/FooterDesktop";
import FooterMobile from "./FooterMobile/FooterMobile";

class Footer extends Component {
  state = { mobileSize: false };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    // console.log(window.innerWidth < 660);
    this.setState({ mobileSize: window.innerWidth < 660 });
  };
  render() {
    return this.state.mobileSize ? <FooterMobile /> : <FooterDesktop />;
  }
}

export default Footer;
