import React from "react";
import classes from "./MapContact.module.css";

const MapContact = () => {
  return (
    <div className={classes.MyLink} style={{ textAlign: "center", marginBottom: 20 }}>
      <div>
        <img
          className={classes.MapSize}
          src={require("../../../assets/images/map/eterno64-map-resized.jpg")}
          alt=""
        />
      </div>
      <div>
        <a href={require("../../../assets/images/map/eterno64_fullsizemap.jpg")} target="blank">
          ดูแผนที่ขนาดใหญ่
        </a>
      </div>
      <div>
        <a
          href="https://www.google.com/maps/place/The+Eterno64/@13.7676996,100.7185122,17z/data=!3m1!4b1!4m5!3m4!1s0x311d66a8a19d49cd:0x639f1e1743bdd6bf!8m2!3d13.7676944!4d100.7207009"
          target="blank"
        >
          Google Maps
        </a>
      </div>
    </div>
  );
};

export default MapContact;
