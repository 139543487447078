import React from "react";
import classes from "./FooterDesktop.module.css";

const FooterDesktop = (props) => {
  return (
    <div className={classes.Footer}>
      <div className={classes.FooterItem}>
        <div className={classes.FooterTitle}>The Eterno</div>
        <div
          style={{
            fontSize: 18,
            color: "var(--mainColor)",
            fontWeight: "bold",
          }}
        >
          Ramkhamhaeng - Kheha Romklao 64
        </div>
        {/* <div style={{ marginTop: 10 }}>
          เกรย์เฟรมคาราโอเกะเดอะแบ็กโฮ ซูโม่ ปัจฉิมนิเทศ ศิลปวัฒนธรรม{" "}
        </div> */}
      </div>
      <div className={classes.FooterItem}>
        <div className={classes.FooterTitle}>Follow Us</div>
        <div style={{ marginTop: 15 }}>
          <a target="blank" href="https://www.facebook.com/107173497296750">
            <img
              alt=""
              src={require("../../../../assets/images/followUsIcon/facebook_logo.png")}
              style={{ width: 35, margin: 5, cursor: "pointer" }}
            />
          </a>
          <a target="blank" href="https://www.instagram.com/eterno64/">
            <img
              alt=""
              src={require("../../../../assets/images/followUsIcon/instagram_logo.png")}
              style={{ width: 35, margin: 5, cursor: "pointer" }}
            />
          </a>
          {/* <a target="blank" href=""> */}
          <img
            alt=""
            src={require("../../../../assets/images/followUsIcon/line_logo.png")}
            style={{ width: 35, margin: 5, cursor: "pointer" }}
          />
          {/* </a> */}
        </div>
      </div>
      <div className={classes.FooterItem}>
        <div className={classes.FooterTitle}>Location</div>
        <div>
          <div style={{ maxWidth: 250 }}>
            ซอยเคหะร่มเกล้า 64 ถนนราษฎร์พัฒนา แขวงคลองสองต้นนุ่น เขตลาดกระบัง
            กรุงเทพมหานคร 10500
          </div>
        </div>
        <div>
          <img
            alt=""
            src={require("../../../../assets/images/phone.png")}
            style={{ width: 25 }}
          />
          <span style={{ marginLeft: 10 }}>0922-675-675</span>
        </div>
      </div>
    </div>
  );
};

export default FooterDesktop;
