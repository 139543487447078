import React, { Component } from "react";
import { Container } from "reactstrap";
import TextBanner from "../../components/LandingPage/TextBanner/TextBanner";
import ProjectDetail from "../../components/LandingPage/ProjectDetail/ProjectDetail";
import BlueprintDetail from "../../components/LandingPage/BlueprintDetail/BlueprintDetail";
import LocationDetail from "../../components/LandingPage/LocationDetail/LocationDetail";
import ImageBanner from "../../components/LandingPage/ImageBanner/ImageBanner";

class LandingPage extends Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <ImageBanner />
        <TextBanner changePageHandler={this.props.changePageHandler} />
        <Container style={{ marginTop: 10 }}>
          <ProjectDetail changePageHandler={this.props.changePageHandler} />
          <BlueprintDetail />
          <LocationDetail />
        </Container>
      </div>
    );
  }
}

export default LandingPage;
